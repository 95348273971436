import Fingerprint2 from "fingerprintjs2"
import Rails from "rails-ujs"

export function track() {
  window.addEventListener('load', function() {
    let tracker = document.querySelector('[data-track-view]')
    if (tracker) {
      // we set a timeout of 2 sec, because we don't want to track right away
      // so that we don't track a mistake on page load
      setTimeout(function() {
        Fingerprint2.get(function (components) {
          const values = components.map(function (component) { return component.value })
          const murmur = Fingerprint2.x64hash128(values.join(''), 31)

          // add necessary CSRF content
          const param = document.querySelector("meta[name=csrf-param]").getAttribute("content")
          const token = document.querySelector("meta[name=csrf-token]").getAttribute("content")

          Rails.ajax({
            type: 'POST',
            url: tracker.dataset.trackView,
            data: `${param}=${token}&fingerprint=${murmur}`,
            success: (response) => {
              Object.keys(response).forEach((key) => {
                let element = tracker.querySelector(`[data-type="${key}"]`)
                if (element) {
                  element.innerText = response[key]
                }
              })
            }
          })
        })
      }, 1000)
    }
  })
}
