import { Controller } from "stimulus"
import Selectable from "../../utils/selectable"

export default class extends Controller {
  static targets = [ "input" ]

  initialize() {
   this.options = [...this.inputTarget.querySelectorAll('option')]
   this.onRefresh = this._refreshOption.bind(this)
  }

  connect() {
    if (this.inputTarget.id) {
      let key = this.inputTarget.id.replace('_', '-')
      this.element.id = `${key}-selectable`
    }

    this.select = new Selectable(this.element, this.options, this.optionsForSelectable)
    this.inputTarget.hidden = true
    this.inputTarget.addEventListener('refresh', this.onRefresh)
  }

  disconnect() {
    if (this.select) this.select.destroy()
    this.inputTarget.hidden = false
  }

  _refreshOption(event) {
    // we don't do nothing if we have a multiple item, this shouldn't ever be the case
    // but we are just safe guarding against weird behaviors
    if (this.inputTarget.multiple) return
    this.inputTarget.value = event.detail
    this.select.refreshValue()
  }

  get optionsForSelectable() {
    const multiple = this.inputTarget.multiple
    return { multiple: multiple, tags: multiple }
  }
}
