import { Controller } from "stimulus"
import * as Utils from "../../utils/common"
import Selectable from "../../utils/selectable"
import RemoteSearch from "../../utils/remote_search"

export default class extends Controller {
  static targets = [ "select", "input", "tags", "tag" ]

  connect() {
    this._buildInterface()
    this.element.addEventListener('resource.created', this._addResource.bind(this))
  }

  add(event) {
    event.stopPropagation()
    this._dismissResponses()
    this._appendTag(event.currentTarget.dataset)
    this.element.dispatchEvent(new CustomEvent('resource.added', { detail: this.tagTargets, bubbles: true }))
    this.inputTarget.value = ''
  }

  doRemove(event) {
    event.stopPropagation()
    event.target.closest('.tag').remove()
    // otherwise we would not trigger the :empty state
    if (this.tagsTarget.children.length === 0) this.tagsTarget.innerHTML = ''
    this.element.dispatchEvent(new CustomEvent('resource.removed', { detail: this.tagTargets, bubbles: true }))
  }

  doCancel(event) {
    event.stopPropagation()
    this._dismissResponses()
  }

  onResponse(response) {
    this._dismissResponses(false)

    const content = response.body.innerHTML
    const responses = document.createRange().createContextualFragment(content).firstElementChild
    // remove any option that we already have in the list
    responses.querySelectorAll('.option').forEach((option) => {
      if (this.ids.includes(option.dataset.id)) option.remove()
    })

    this.element.querySelector('.controls').appendChild(responses)
    this.element.querySelectorAll('.option').forEach((button) => button.addEventListener('click', this.add.bind(this)))
  }

  _dismissResponses(animated = true) {
    const responses = this.element.querySelector('.responses')
    if (!responses) return
    Selectable.clearResponses(responses, animated)
  }

  _doSearch() {
    const search = this.inputTarget
    if (search.value.length < 3) return

    if (!this.src) return
    const query = search.value.trim()
    const params = { term: query, create: this.allowCreate }
    RemoteSearch.fetch(this.src, params, this.onResponse.bind(this))
  }

  _buildInterface() {
    if (this.hasSelectTarget) {
      this.source = this.selectTarget.name
      this._createSearchInput()

      const container = `<div class="tags" data-empty="${this.emptyState}" data-target="${this.scope.identifier}.tags"></div>`
      this.element.insertAdjacentHTML('beforeend', container)
      this.selectedOptions.forEach((option) => this._appendTag({ id: option.value, name: option.innerText }))

      // we will remove the select target because we will handle all the input changing
      // we our newest components added to the interface
      this.selectTarget.remove()
      this.element.dispatchEvent(new CustomEvent('resources.loaded', { bubbles: true, detail: this.tagTargets }))
    }
  }

  _createTag(text, value) {
    return `
      <span class="tag">
        ${text}
        <input type="hidden" name="${this.source}" value="${value}" data-name="${text}" data-target="${this.scope.identifier}.tag" />
        <button type="button" class="remove" data-action="${this.scope.identifier}#doRemove"></button>
      </span>`
  }

  _createSearchInput() {
    const search = `
      <div class="controls">
        <input type="text" data-target="${this.scope.identifier}.input" placeholder="${this.label}" />
      </div>
    `

    this.element.insertAdjacentHTML('beforeend', search)
    this.inputTarget.addEventListener('input', Utils.debounce(this._doSearch.bind(this), 250))
  }

  _addResource(event) {
    this._dismissResponses()
    this._appendTag(event.detail)
    this.element.dispatchEvent(new CustomEvent('resource.added', { detail: event.detail, bubbles: true }))
    this.inputTarget.value = ''
  }

  _appendTag(tag) {
    this.tagsTarget.insertAdjacentHTML('beforeend', this._createTag(tag.name, tag.id))
  }

  get ids() {
    return [...this.tagsTarget.querySelectorAll('input[type="hidden"]')].map((tag) => tag.value)
  }

  get label() {
    return this.data.get('label') || ''
  }

  get src() {
    return this.data.get('url')
  }

  get allowCreate() {
    return this.data.get('create') || false
  }

  get selectedOptions() {
    return this.selectTarget.querySelectorAll('option[selected]')
  }

  get emptyState() {
    return '0 selected'
  }
}
