import { Controller } from "stimulus"
import * as Utils from "../../utils/common"
import Selectable from "../../utils/selectable"
import RemoteSearch from "../../utils/remote_search"
import ClickOutside from "../../utils/click_outside"

export default class extends Controller {
  static targets = [ "selection", "options", "template", "responses", "search", "button" ]

  initialize() {
    this.onKeyUp = this._onKeyUp.bind(this)
    this.onSearch = Utils.debounce(this._search.bind(this), 250)
    this.multiple = this.optionsTarget.multiple
  }

  connect() {
    this._createSearchInput()

    if (this.multiple) {
      this._createSelectionTarget()
      this._displaySelection()
    } else {
      if (this.selectedOption) {
        this.searchTarget.value = this.selectedOption.innerText
        this._closeMode()
      }

      if (this.country) this._trackCountryChange()
    }

    this.element.addEventListener('keyup', this.onKeyUp)
    this.searchTarget.addEventListener('input', this.onSearch)
  }

  disconnect() {
    this.element.removeEventListener('keyup', this.onKeyUp)
    this.searchTarget.removeEventListener('input', this.onSearch)
  }

  doSearch(event) {
    let button = event.currentTarget
    button.dataset.type === 'remove' ? this._searchMode() : this._search()
  }

  onResponse(response) {
    this._dismissResponses(false)
    this.controlsElement.insertAdjacentHTML('beforeend', response.body.innerHTML)
    this.controlButton.classList.remove('is-searching')
    ClickOutside.register(this, this._clickingOutside)
  }

  onSelect(event) {
    let element = event.currentTarget

    if (this.multiple) {
      // validate that we don't have the item already in the list
      let selected = this.optionsTarget.querySelector(`option[value="${element.dataset.id}"]`)
      this.searchTarget.value = ''
      if (!selected) {
        this._createOption(element)
        this._displaySelection()
      }
    } else {
      // update this we have a country select element but the value is empty
      if (this.country) this.country.dispatchEvent(new CustomEvent('refresh', { detail: element.dataset.country }))
      this.searchTarget.value = element.dataset.name
      this.optionsTarget.innerHTML = ''
      this._createOption(element)
      this._closeMode()
    }

    this.element.dispatchEvent(new CustomEvent('selected', { detail: element.dataset }))
    this._dismissResponses()
  }

  doCancel() {
    this.searchTarget.value = ''
    this.searchTarget.focus()
    this._dismissResponses()
  }

  onDelete(event) {
    event.preventDefault()
    let element = event.currentTarget
    let option = this.optionsTarget.querySelector(`option[value="${element.dataset.id}"]`)
    if (option) {
      option.remove()
    }
    element.parentNode.remove()
  }

  _createSearchInput() {
    this.optionsTarget.hidden = true
    const required = this.optionsTarget.required ? 'required="required"' : ''
    const input = `
      <input ${required} type="text" data-target="${this.scope.identifier}.search" placeholder="Search for locations (min. 3 characters)" />
    `
    this.optionsTarget.insertAdjacentHTML('beforebegin', input)
  }

  _createOption(element) {
    let data = element.dataset
    let option = `
      <option value="${data.id}" data-country="${data.country}" selected>${data.name}, ${data.country}</option>
    `
    this.optionsTarget.insertAdjacentHTML('beforeend', option)
  }

  _displaySelection() {
    let selected = this.optionsTarget.querySelectorAll('option[selected]')
    this.selectionTarget.innerHTML = ''
    selected.forEach((option) => this.selectionTarget.appendChild(this._selectionItem(option)))
  }

  _selectionItem(option) {
    let element = document.createRange().createContextualFragment(this.templateTarget.innerHTML)
    element.querySelector('.city-name').innerText = option.innerText
    element.querySelector('button').dataset.id = option.value
    return element
  }

  _createSelectionTarget() {
    let element = `<div class="mt-3" data-target="${this.scope.identifier}.selection"></div>`
    this.element.insertAdjacentHTML('beforeend', element)
  }

  _setMode(type, icon) {
    this.buttonTarget.dataset.type = type
    this.buttonTarget.querySelector('i').innerText = icon
  }

  _closeMode() {
    this._setMode('remove', 'close')
    this.searchTarget.setAttribute('readonly', 'readonly')
  }

  _searchMode() {
    this._setMode('search', 'search')
    this.optionsTarget.value = ''
    this.searchTarget.value = ''
    this.searchTarget.removeAttribute('readonly')
    this.element.dispatchEvent(new CustomEvent('deselected'))
  }

  _getCountry() {
    let select = this.data.get('select')
    if (!select) return null
    return document.querySelector(select).value.toUpperCase()
  }

  _onKeyUp(event) {
    if (event.code === 'Escape') this._clickingOutside()
  }

  _search() {
    const search = this.searchTarget
    if (search.value.length < 3) return

    let params = { term: search.value }
    const country = this._getCountry()
    if (country) params.country = country

    this.controlButton.classList.add('is-searching')
    RemoteSearch.fetch('/account/searches/cities', params, this.onResponse.bind(this))
  }

  _dismissResponses(animated = true) {
    if (!this.hasResponsesTarget) return
    Selectable.clearResponses(this.responsesTarget, animated)
    ClickOutside.deregister()
  }

  _clickingOutside() {
    if (this.selectedOption) {
      this.searchTarget.value = this.selectedOption.innerText
      this._closeMode()
    }
    this._dismissResponses()
  }

  _trackCountryChange() {
    this.country
        .closest('.searchable')
        .addEventListener('selected', this._onCountryChange.bind(this))
  }

  _onCountryChange() {
    if (this.selectedOption) this.selectedOption.selected = false
    this._searchMode()
  }

  get controlsElement() {
    return this.element.querySelector('.controls')
  }

  get controlButton() {
    return this.controlsElement.querySelector('.control-button')
  }

  get selectedOption() {
    return this.optionsTarget.querySelector('option[selected]')
  }

  get country() {
    const target = this.data.get('select')
    if (target) {
      const element = document.querySelector(target)
      return element ? element : null
    }
    return null
  }
}
