import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add(event) {
    event.preventDefault()

    let content = this.templateTarget.innerHTML.replace(/RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)
    let input = wrapper.querySelector("input[name*='_destroy']")

    if (input) {
      input.value = 1
      wrapper.style.display = 'none'
    } else {
      wrapper.remove()
    }
  }
}
