import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "minimum", "maximum" ]

  connect() {
    this.minimumTarget.dataset.action = this._defaultEvents().join(' ')
    this.maximumTarget.dataset.action = this._defaultEvents().join(' ')
  }

  validateNumeric(event) {
    let element = event.currentTarget
    element.value = element.value.replace(/[^0-9.]/g, '')
  }

  validateRange() {
    let minimum = this.minimumTarget
    let maximum = this.maximumTarget

    // we don't do nothing if one of them is empty
    if (minimum.value === '' || maximum.value === '') {
      return
    }

    if (parseInt(minimum.value) > parseInt(maximum.value)) {
      let swap = minimum.value
      minimum.value = maximum.value
      maximum.value = swap
    }
  }

  _defaultEvents() {
    return ['keydown->jobs--salary#validateNumeric', 'change->jobs--salary#validateRange']
  }
}
