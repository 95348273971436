import { Controller } from "stimulus"
import ModalManager from "../../managers/modal_manager"

export default class extends Controller {
  onClick(event) {
    if (this._isDesktop()) {
      let element = event.currentTarget
      event.preventDefault()
      ModalManager.launch(element.href, element)
    }
  }

  doDismiss(event) {
    if (this._isDesktop()) {
      event.preventDefault()
      ModalManager.dismiss()
    }
  }

  _isDesktop() {
    return window.matchMedia('(min-width: 1024px)').matches
  }
}
