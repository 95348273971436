import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "options", "option", "counter" ]

  connect() {
    // we will check if we have an all `data-` attribute if we have we will add a button
    // that will allow us to toggle between select/deselect all the items
    if (this.label) {
      this._addAllToggle()
    }

    this._setSubmitStatus()
  }

  onSelection() {
    this._setSubmitStatus()
  }

  doSearch() {
    this.element.dispatchEvent(new CustomEvent('search', { bubbles: true }))
  }

  _addAllToggle() {
    const total = this.optionsTargets.length
    if (total < 2) return

    let identifier = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    // create the base wrapper for the checkbox element
    let wrapper = document.createElement('div')
    wrapper.classList.add('dropdown-checkbox')
    // create the checkbox functionality element
    let checkbox = document.createElement('span')
    checkbox.classList.add('checkbox')
    wrapper.appendChild(checkbox)
    // the checkbox effective input
    let input = document.createElement('input')
    input.id = identifier
    input.type = 'checkbox'
    input.addEventListener('change', this._onSelectAll.bind(this))
    this.selectAll = input
    checkbox.appendChild(input)
    // the label for the input
    let label = document.createElement('label')
    label.setAttribute('for', identifier)
    label.innerText = this.label
    checkbox.appendChild(label)
    this.optionsTarget.prepend(wrapper)
  }

  _onSelectAll(event) {
    let input = event.currentTarget
    this.optionsTarget.querySelectorAll('input[type="checkbox"]').forEach((element) => element.checked = input.checked)
    this._setSubmitStatus()
  }

  _setSubmitStatus() {
    /*
    if (this.selected.length > 0) {
      this.buttonTarget.removeAttribute('disabled')
    } else {
      this.buttonTarget.setAttribute('disabled', true)
    }
    */

    this._setCounter()
    this._setSelectAllStatus()
  }

  _setCounter() {
    if (this.hasCounterTarget) {
      let selected = this.selected.length
      this.counterTarget.innerText = selected > 0 ? selected : null
    }
  }

  _setSelectAllStatus() {
    if (this.selectAll) {
      const checkboxes = this.optionTargets
      const checked = [...checkboxes].filter((checkbox) => checkbox.checked)
      this.selectAll.checked = checked.length === checkboxes.length
    }
  }

  get label() {
    return this.data.get('all')
  }

  get selected() {
    return [...this.optionTargets].filter((element) => element.checked)
  }
}
