import { Controller } from "stimulus"
import Rails from "rails-ujs"
import qs from "qs"
import UploadManager from "../../managers/upload_manager"
import FlashManager from "../../managers/flash_manager"

export default class extends Controller {
  static targets = [ "dropzone", "button", "pivot" ]

  // REVIEW: maybe we can simplify this, need to compare this against the `uploader_controller` and see what are
  // the differences

  initialize() {
    this.uploadable = true
  }

  connect() {
    this._createPivotInput()
  }

  _createPivotInput() {
    // we check if we have an uploader in the element, we only setup the pivot input if we have
    // an element tagged `.mUploader` there, otherwise, we already have an image uploaded for this
    // block
    let uploader = this.element.querySelector('.mUploader')
    if (uploader) {
      let identifier = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      let label = `pivot-${identifier}`
      let pivot = document.createElement('input')
      pivot.id = label
      pivot.type = 'file'
      pivot.classList.add('hidden')
      pivot.dataset.action = `change->${this.scope.identifier}#showPreview`
      pivot.dataset.target = `${this.scope.identifier}.pivot`
      pivot.accept = 'image/png, image/jpeg'
      this.element.appendChild(pivot)
      this.buttonTarget.setAttribute('for', label)
      this.dropzoneTarget.dataset.action = `click->${this.scope.identifier}#openFileBrowser`
      this.uploader = new UploadManager(this.pivotTarget, this.dropzoneTarget)
      this.label = this.dropzoneTarget.dataset.label
    }
  }

  showPreview(event) {
    if (!this.uploadable) return

    let file = event.target.files[0]

    if (this._validateField(file)) {
      // clear any error message being displayed
      this._clearErrorMessage()

      let blobURL = URL.createObjectURL(file)
      this._setPreviewImage(blobURL)
      this.buttonTarget.remove()

      // we will check if a position is supplied, we will used it, otherwise we allow the system
      // handle the position of the image
      let target = this.element.closest('.section-block')
      let params = { image: { section_id: target.dataset.section } }
      if (this.data.get('position')) {
        params.position = this.data.get('position')
      }

      this.form.dispatchEvent(new CustomEvent('upload:start'))

      this.uploader
        .doDirectUpload(file, this.dropzoneTarget)
        .then((blob) => {
          params.image.resource = blob.signed_id

          // now we will create the image on the server, and this will in the future trigger
          // the job to start tagging and add things to the image
          Rails.ajax({
            type: 'POST',
            url: Routes.images.create,
            data: qs.stringify(params),
            dataType: 'json',
            success: (response) => {
              this.dropzoneTarget.classList.remove(UploadManager.classForUploading())
              this._makeEditable(response)
              this.uploadable = false
              this.form.dispatchEvent(new CustomEvent('upload:end'))
            },
            error: (response) => FlashManager.showFlash(response.error, 'alert')
          })
        })
    }
  }

  openFileBrowser() {
    if (this.uploader && this.uploadable) this.uploader.openFileBrowser()
  }

  _setPreviewImage(url) {
    let image = new Image()
    image.src = url
    image.alt = ''
    this.dropzoneTarget.appendChild(image)
    this.dropzoneTarget.classList.add(UploadManager.classForPreviewing())
  }

  // basically we will create the button to delete
  // and the button to edit the image
  _makeEditable(image) {
    let actions = this.element.querySelector('.actions')

    let link = document.createElement('a')
    link.href = image.url
    link.classList.add('editor-button')
    link.dataset.controller = 'ui--modal'
    link.dataset.action = 'ui--modal#onClick'
    link.innerText = 'edit'
    actions.appendChild(link)
  }

  _validateField(file) {
    // we only accept JPG and PNG files, and we have a maximum size of 10MB
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      this._setErrorMessage()
      return false
    }

    // convert the bytes to megabytes (meaning diving by 1024, 2 times)
    const size = file.size / 1024 / 1024
    if (size > 10) {
      this._setErrorMessage('size')
      return false
    }

    return true
  }

  // IDEA: maybe we can set a timeout and revert to the initial state after that period
  _setErrorMessage(type) {
    const message = type === 'size' ? this.errorForSize : this.errorForType
    this.dropzoneTarget.dataset.label = message
    this.dropzoneTarget.classList.add('has-error')
  }

  _clearErrorMessage() {
    this.dropzoneTarget.dataset.label = this.label
    this.dropzoneTarget.classList.remove('has-error')
  }

  get errorForSize() {
    return this.data.get('errorSize') || 'This file exceeds the maximum file size allowed.'
  }

  get errorForType() {
    return this.data.get('errorType') || 'Only JPG or PNG files are allowed.'
  }

  get form() {
    return this.element.closest('form')
  }
}
