import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "rate", "star" ]

  connect() {
    this.total = this.rateTargets.length
    this.rateTargets.forEach((target) => target.style.display = 'none')
    this.ratings = this.rateTargets.map((rate) => rate.querySelector('input'))
    this._buildStarList()
    this._setSelected()
  }

  disconnect() {
    const container = this.element.querySelector('rating-stars')
    if (container) container.remove()
  }

  onMouseOver(event) {
    this._toggleState(event.currentTarget.dataset.position)
  }

  onMouseOut() {
    this._setSelected()
  }

  doSelect(event) {
    const position = parseInt(event.currentTarget.dataset.position)
    const rate = this.ratings[position]
    rate.checked = true
    this._toggleState(position)
  }

  _toggleState(position) {
    if (!Number.isInteger(position)) position = parseInt(position)

    for (let i = 0; i < this.starTargets.length; i++) {
      const element = this.starTargets[i]
      element.classList.toggle('text-blue-100', i <= position)
    }
  }

  _buildStarList() {
    const container = document.createElement('div')
    container.classList.add('rating-stars')
    this.element.appendChild(container)

    for (let i = 0; i < this.total; i++) {
      let element = document.createElement('button')
      element.classList.add('rating-star')
      element.type = 'button'
      element.innerHTML = '<i class="material-icons-outlined align-middle">star</i>'
      element.dataset.target = `${this.scope.identifier}.star`
      element.dataset.action = this._eventsForButton()
      element.dataset.position = i
      container.appendChild(element)
    }
  }

  _setSelected() {
    const checked = this.ratings.find((rate) => rate.checked)
    if (checked) {
      const position = parseInt(checked.value) - 1
      this._toggleState(position)
    }
  }

  _eventsForButton() {
    return [
      `mouseover->${this.scope.identifier}#onMouseOver`,
      `mouseout->${this.scope.identifier}#onMouseOut`,
      `${this.scope.identifier}#doSelect`
    ].join(' ')
  }
}
