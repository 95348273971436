import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "user", "companies", "deselect" ]

  connect() {
    const options = this.companiesOptions
    if (options.length > 0) {
      if (!this.selectedCompany) this.deselectTarget.classList.add('disabled')
      this.companiesOptions.forEach((input) => {
        input.addEventListener('change', (event) => {
          const target = event.currentTarget
          if (target.checked) this.deselectTarget.classList.remove('disabled')
        })
      })
    } else {
      this.deselectTarget.classList.add('hidden')
    }
  }

  deselect() {
    this.companiesOptions.forEach((input) => input.checked = false)
    this.userTarget.checked = true
    this.deselectTarget.classList.add('disabled')
  }

  get companiesOptions() {
    return this.companiesTarget.querySelectorAll('input[type="radio"]')
  }

  get selectedCompany() {
    return [...this.companiesOptions].find((input) => input.checked)
  }
}
