import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "area", "button", "notification" ]

  initialize() {
    this.loaded = false
  }

  doLoad() {
    if (!this.loaded) {
      Rails.ajax({
        type: 'GET',
        url: Routes.notifications.list,
        dataType: 'json',
        success: (response) => {
          this.loaded = true
          if (response.template) this.areaTarget.innerHTML = response.template
        }
      })
    }
  }

  clear() {
    Rails.ajax({
      type: 'DELETE',
      url: Routes.notifications.clear,
      dataType: 'json',
      success: (response) => {
        if (response.cleared) {
          if (this.hasNotificationTarget) this.notificationTarget.remove()
          if (this.hasButtonTarget) this.buttonTarget.classList.add('hidden')

          const notifications = this.areaTarget.querySelectorAll('.notification')
          notifications.forEach((element) => element.classList.add('notification--read') )
        }
      }
    })
  }
}
