import { DirectUpload } from "@rails/activestorage"

class UploadManager {
  static classForUploading() { return 'is-uploading' }
  static classForPreviewing() { return 'is-previewing' }

  constructor(input, dropzone = null) {
    this.input = input
    this.dropzone = dropzone
    this.addListeners()
    this.allowedTypes = []
  }

  addListeners() {
    if (this.dropzone) {
      this.dropzone.addEventListener('dragover', this.onDragOver.bind(this))
      this.dropzone.addEventListener('drop', this.onDrop.bind(this))
    }
  }

  onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  onDrop(event) {
    event.preventDefault()
    this.input.files = event.dataTransfer.files

    // we need to validate if we accept many files upload or not and act accordingly
    // then we need to validate if the files supplied are the ones allowed by the input
    if (!this.isValid()) {
      this.input.files = null
      return
    }

    this.input.dispatchEvent(new Event('change'))
  }

  openFileBrowser() {
    this.input.click()
  }

  doDirectUpload(file, anchor = null) {
    this.anchor = anchor

    return new Promise((resolve, reject) => {
      const upload = new DirectUpload(file, Routes.directUpload, this)
      upload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    let progress = Math.round(event.loaded / event.total * 100)
    if (this.anchor) {
      this.anchor.setAttribute('data-progress', `${progress}%`)
    }
  }

  isValid() {
    return this._isValidQuantity() && this._isValidFormat()
  }

  _isValidQuantity() {
    return this.input.multiple || (this.input.files.length == 1 && !this.input.multiple)
  }

  _isValidFormat() {
    return [...this.input.files].every(file => ['image/jpeg', 'image/png'].includes(file.type))
  }
}

export default UploadManager
