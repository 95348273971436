/* eslint no-console:0 */
require("@rails/actiontext")

import "fonts"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "flatpickr/dist/themes/light.css"
import "trix/dist/trix.css"
import "basiclightbox/dist/basicLightbox.min.css"
import "reinvented-color-wheel/css/reinvented-color-wheel.min.css"
import "plyr/dist/plyr.css"
import "css/application.css"
import "controllers"

import Rails from "rails-ujs"
import Trix from "trix"
import * as ViewTracker from "../utils/tracker"

window.addEventListener('trix-before-initialize', () => {
  // change the default `tagName` on Trix for headings
  Trix.config.blockAttributes.heading1.tagName = 'h2'
})

// import all images
require.context('../images', true)

Rails.start()
ViewTracker.track()

if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
  const outdatedBrowser = document.querySelector('.outdated-browser')
  if (outdatedBrowser) outdatedBrowser.classList.remove('hidden')
}
