import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "track", "slides", "controls", "preview" ]

  initialize() {
    this.currentSlide = this.slidesTargets[0]
  }

  connect() {
    if (this.totalSlides <= 1) {
      if (this.hasControlsTarget) this.controlsTarget.classList.add('hidden')
    } else if (this.hasControlsTarget) {
      this._setControls()
    }

    this._setPreviews()
  }

  disconnect() {
    this.element.classList.remove('overflow-x-hidden')
  }

  doMove(event) {
    const target = event.target
    if (target.classList.contains(this.classForDisabled)) return

    let indexForSlide = target.dataset.type === 'prev' ? this.currentIndex - 1 : this.currentIndex + 1
    this.currentSlide = this.slidesTargets[indexForSlide]
    this._setControls()
    this._move()
  }

  showSlide(event) {
    const target = event.currentTarget
    this.currentSlide = this.slidesTargets[parseInt(target.dataset.slide)]
    this._move()
  }

  _move() {
    const location = this.currentSlide.clientWidth * this.currentIndex
    this.trackTarget.style.transform = `translateX(-${location}px)`
    this._setPreviews()
  }

  _setPreviews() {
    if (this.hasPreviewTarget) {
      const previews = [...this.previewTargets]
      previews.forEach((preview) => preview.classList.remove(this.classForActive))
      previews[this.currentIndex].classList.add(this.classForActive)

      const previewStage = this.element.querySelector('.slider-previews')
      previewStage.addEventListener('wheel', (event) => {
        event.preventDefault()

        if (event.deltaY > 0) previewStage.scrollLeft += 30
        else previewStage.scrollLeft -= 30
      })
    }
  }

  _setControls() {
    const previous = this.previousButton.classList
    const next = this.nextButton.classList

    if (this.currentIndex === 0) {
      // disable previous button
      previous.add(this.classForDisabled)
      next.remove(this.classForDisabled)
    } else if (this.currentIndex === this.totalSlides - 1) {
      // disable next button
      previous.remove(this.classForDisabled)
      next.add(this.classForDisabled)
    } else {
      // enable both of them
      previous.remove(this.classForDisabled)
      next.remove(this.classForDisabled)
    }
  }

  get currentIndex() {
    return this.slidesTargets.indexOf(this.currentSlide)
  }

  get totalSlides() {
    return this.slidesTargets.length
  }

  get previousButton() {
    return this.controlsTarget.querySelector('[data-type="prev"]')
  }

  get nextButton() {
    return this.controlsTarget.querySelector('[data-type="next"]')
  }

  get classForDisabled() {
    return 'is-disabled'
  }

  get classForActive() {
    return 'is-active'
  }
}
