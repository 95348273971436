import { Controller } from "stimulus"
import { Sortable } from "@shopify/draggable"
import BlockManager from "../../managers/block_manager"

export default class extends Controller {
  static targets = [ "item" ]

  initialize() {
    this.onFileAccept = this._discardAttachment.bind(this)
    this.onAttachmentAdd = this._deleteAttachment.bind(this)
  }

  connect() {
    this.classForPreview = 'thumb-preview'
    this.classForDeletedPreview = 'thumb-preview--deleted'
    this._initializeSortable()
    this.element.addEventListener('trix-file-accept', this.onFileAccept)
    this.element.addEventListener('trix-attachment-add', this.onAttachmentAdd)
  }

  disconnect() {
    this.element.removeEventListener('trix-file-accept', this.onFileAccept)
    this.element.removeEventListener('trix-attachment-add', this.onAttachmentAdd)
  }

  doRemove(event) {
    event.preventDefault()
    let button = event.currentTarget
    let section = button.closest(`.${BlockManager.classForSection()}`)
    let destroy = section.querySelector('[name*=_destroy]')
    if (destroy) {
      destroy.value = 1
      // we also need to validate that there aren't any required element in this
      // section, otherwise validation will fail
      this._scrubSection(section)
      // now we dispatch a custom event to be caught by the form controller
      section.classList.add('hidden', BlockManager.classForDeletedSection())
      section.dispatchEvent(new CustomEvent('section:change', { bubbles: true }))
      BlockManager.reorder(this.itemTargets)
    }
  }

  doRemoveImage(event) {
    event.preventDefault()
    let button = event.currentTarget
    let section = button.closest(`.${this.classForPreview}`)
    let destroy = section.querySelector('[name*=_destroy]')
    if (destroy) {
      destroy.value = 1
      section.classList.add('hidden', this.classForDeletedPreview)
    }
  }

  _initializeSortable() {
    this.sortable = new Sortable(this.element, {
      draggable: `.${BlockManager.classForSection()}`,
      handle: '.sortable-handle',
      mirror: {
        xAxis: false,
        constrainDimensions: true
      }
    })
    // we are setting a timeout of 100ms, because otherwise we would get the mirror and
    // draggable element over in our list of elements, sure there would exist other approaches
    // but this one seems to work
    this.sortable.on('sortable:stop', () => setTimeout(() => BlockManager.reorder(this.itemTargets), 100))
  }

  _scrubSection(section) {
    // at the moment the only thing that we will do is to remove all the input text and url inputs
    const inputs = section.querySelectorAll('input[type="text"], input[type="url"]')
    inputs.forEach((input) => input.remove())
  }

  _discardAttachment(event) {
    event.preventDefault()
  }

  _deleteAttachment(event) {
    event.attachment.remove()
  }
}
