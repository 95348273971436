import { Controller } from "stimulus"
import Selectable from "../../utils/selectable"

export default class extends Controller {
  static targets = [ "options", "button", "placeholder", "tags", "template" ]

  initialize() {
    this.onSelected = this._onSelect.bind(this)
    this.onDeselected = this._onDeselect.bind(this)
  }

  connect() {
    this.select = new Selectable(this.placeholderTarget, this.availableOptions, { multiple: true })
    this._addEventListeners()
  }

  disconnect() {
    this.select.destroy()
    this.placeholderTarget.removeEventListener('selected', this.onSelected)
    this.placeholderTarget.removeEventListener('deselected', this.onDeselected)
  }

  remove(event) {
    event.preventDefault()
    this._removeOption(event.target)
    // reload options, because we need to update the current list and send it to
    // the select component
    this.select.refreshOptions(this.availableOptions)
  }

  _addEventListeners() {
    this.placeholderTarget.addEventListener('selected', this.onSelected)
    this.placeholderTarget.addEventListener('deselected', this.onDeselected)
  }

  _onSelect(event) {
    let option = event.detail

    let content = this.templateTarget.innerHTML.replace(/RECORD/g, new Date().getTime())
    let pivot = document.createRange().createContextualFragment(content)
    if (option && option.value !== '') {
      pivot.querySelector('input[data-source]').value = option.value
      pivot.querySelector('.tag-name').innerText = option.text
      this.tagsTarget.appendChild(pivot)
    }
  }

  _onDeselect(event) {
    const matches = this.tagsTarget.querySelectorAll(`[value="${event.detail.value}"]`)
    matches.forEach((match) => this._removeOption(match))

    // reload options, because we need to update the current list and send it to
    // the select component
    this.select.refreshOptions(this.availableOptions)
  }

  _removeOption(option) {
    const wrapper = option.closest('.tag')
    let input = wrapper.querySelector('input[name*="_destroy"]')

    if (input) {
      input.value = 1
      wrapper.style.display = 'none'
    } else {
      wrapper.remove()
    }
  }

  get availableOptions() {
    const selected = this.selectedOptions
    let options = [...this.optionsTarget.options]

    options.forEach((option) => {
      option.selected = selected.includes(option.value)
    })

    return options
  }

  get selectedOptions() {
    return [...this.tagsTarget.querySelectorAll('.tag')]
      .filter((option) => {
        return parseInt(option.querySelector('input[name*=_destroy]').value) !== 1
      })
      .map((option) => option.querySelector('input[data-source]').value.toUpperCase())
  }
}
