import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "list", "provider" ]

  add(event) {
    event.preventDefault()

    let selected = this.providerTarget.value
    let option = this.providerTarget.querySelector(`option[value="${selected}"]`)

    // we need to check if we don't have any previously deleted item here, if we have
    // we restore them because it's more efficient
    let field = this.listTarget.querySelector(`.network[data-name="${option.innerText}"]`)
    if (field) {
      let element = field.closest('.network')
      field.querySelector('input[name*=_destroy]').value = 0
      element.style.display = 'flex'
      element.querySelector('input[name*=username]').focus()
    } else {
      let element = this._createElement(option)
      element.querySelector('input[name*=username]').focus()
    }

    // remove the option from select
    option.remove()

    // we will disable the parent element, that will disable everything
    if (this.providerTarget.value === '') {
      this.providerTarget.closest('.network-add').classList.add('disabled')
    }
  }

  remove(event) {
    event.preventDefault()
    let button = event.currentTarget
    let wrapper = button.closest('.network')
    this._revertOption(wrapper)

    let input = wrapper.querySelector("input[name*='_destroy']")
    if (input) {
      input.value = 1
      wrapper.style.display = 'none'
    } else {
      wrapper.remove()
    }
  }

  _createElement(option) {
    let content = this.templateTarget.innerHTML.replace(/RECORD/g, new Date().getTime())
    let pivot = document.createRange().createContextualFragment(content)
    let element = pivot.firstChild
    element.dataset.name = option.innerText

    let provider = element.querySelector('input[name*=provider]')
    provider.value = option.value

    let iconWrapper = element.querySelector('.network-icon')
    let iconElement = iconWrapper.querySelector('.fab')
    iconElement.classList.remove('fa-__ICON__')
    iconElement.classList.add(`fa-${option.dataset.icon}`)

    // we will also focus the network username field
    this.listTarget.prepend(pivot)

    return element
  }

  _revertOption(wrapper) {
    let option = document.createElement('option')
    option.value = wrapper.querySelector('input[name*=provider]').value
    option.dataset.icon = wrapper.querySelector('.network-icon').dataset.icon
    option.innerText = wrapper.dataset.name
    this.providerTarget.appendChild(option)
    this.providerTarget.closest('.network-add').classList.remove('disabled')
  }
}
