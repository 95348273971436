import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "text" ]

  connect() {
    this.element.dataset.action = `${this.scope.identifier}#doClick`
  }

  doClick() {
    this.element.classList.add('disabled')
    this.textTarget.innerText = this.label
  }

  get label() {
    return this.data.get('label') || 'Searching...'
  }
}
