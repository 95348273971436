import { Controller } from "stimulus"
import * as basicLightbox from "basiclightbox"

export default class extends Controller {
  initialize() {
    this.onClick = this._onClick.bind(this)
  }

  connect() {
    this.element.addEventListener('click', this.onClick)
  }

  disconnect() {
    this.element.removeEventListener('click', this.onClick)
  }

  _onClick(event) {
    event.preventDefault()

    if (this.source === 'html') {
      basicLightbox.create(this.element.innerHTML).show()
    } else if (this.source === 'self') {
      basicLightbox.create(`<img src="${this.element.href}" alt="" />`).show()
    }
  }

  get source() {
    return this.data.get('source') || 'html'
  }
}
