import Rails from "rails-ujs"
import FlashManager from "./flash_manager"

class ModalManager {
  // IMPROVEMENT: it would be good to have some accessibility features in mind when opening the dialog
  // like add some `aria` tags.
  // For more info: https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html

  // IMPROVEMENT: inject form tracker here, so that we can check if a form has changed, this way we can
  // validate if any change to the form happened if the user clicks outside the modal, right now we just
  // close the modal without any changes saved.

  constructor() {
    this.listener = this._onInteraction.bind(this)
    this.modal = null
    this.trigger = null
    this.overlay = null

    const mql = window.matchMedia('(orientation: landscape)')
    mql.addListener(this._repositionModal.bind(this))
  }

  isOpen() {
    return this.modal !== null
  }

  launchFromTemplate(template, anchor) {
    this._showModal(template, this._validateAnchor(anchor))
  }

  launch(url, anchor) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (response) => this._showModal(response.template, this._validateAnchor(anchor)),
      error: (response) => this._showAlert(response.error)
    })
  }

  dismiss() {
    this._dismissModal()
    this._dismissTrigger()
    this._dismissOverlay()
    this._removeListeners()
  }

  _showModal(template, anchor) {
    this.dismiss()

    // we will create a document fragment based on the template that we received
    let fragment = document.createRange().createContextualFragment(template)
    let modal = fragment.firstChild

    modal.classList.remove('modalized')
    modal.classList.add('absolute', 'modal', 'z-50')

    // we need to append to the body right away, so that we can have access to the
    // modal dimensions
    document.body.appendChild(modal)

    // we will store both the modal and anchor to later reuse
    this.modal = modal
    this.trigger = anchor
    this._placeModal()
    this._handleForm()
    this._addListeners()
  }

  _placeModal() {
    let modal = this.modal
    let anchor = this.trigger

    // get dimensions of the modal
    let modalWidth = modal.clientWidth,
        modalHeight = modal.clientHeight

    if (this._isPortrait()) this.modal.classList.add('max-w-xl')

    // if our modal is attached to the element supplied we need to do some math
    // and position the element accordingly
    if (anchor.dataset.attached) {
      let position = anchor.getBoundingClientRect(),
          anchorWidth = anchor.clientWidth,
          anchorHeight = anchor.clientHeight,
          xPosition, yPosition

      xPosition = ((position.x + window.scrollX) + (anchorWidth / 2)) - (modalWidth / 2)
      // where 16 is the top margin that we want to give to the modal
      yPosition = (position.y + window.scrollY) + anchorHeight + 16

      // we need to do some sanitization, because there's a chance of the modal go offscreen
      // so we will need to check if that is true
      if (xPosition + modalWidth > document.body.clientWidth) {
        xPosition = (position.x + anchorWidth) - modalWidth
      }

      modal.style.left = `${xPosition}px`
      modal.style.top = `${yPosition}px`

      this._stickAnchor(anchor)
    } else {
      let windowWidth = document.body.clientWidth,
          windowHeight = window.innerHeight,
          scrollPosition = window.scrollY,
          xPosition = (windowWidth / 2) - (modalWidth / 2),
          yPosition = scrollPosition + (window.innerHeight / 2) - (modalHeight / 2)

      // to avoid that the modal overflows the layout we need to check if we are near the bottom
      // of the page, if we are we need compute the modal to be near the bottom and don't overlap it
      if (windowHeight + window.scrollY > document.body.clientHeight) {
        yPosition = window.scrollY - (modal.clientHeight - windowHeight)
      } else if (windowHeight <= modal.clientHeight) {
        // we need to deal with viewports that have a smaller height than the
        // modal height, we will just add it a little less below,
        // instead of center the modal in the viewport
        yPosition = window.scrollY
      }

      // reposition the element
      modal.style.left = `${xPosition}px`
      modal.style.top = `${yPosition}px`

      // display an overlay below the modal
      this._createOverlay()
    }

    this._focus()
  }

  _addListeners() {
    document.addEventListener('keyup', this.listener)
    document.addEventListener('click', this.listener)
  }

  _removeListeners() {
    document.removeEventListener('keyup', this.listener)
    document.removeEventListener('click', this.listener)
  }

  _onInteraction(event) {
    let shouldDismiss = false
    switch(event.type) {
      case 'click':
        shouldDismiss = !this.modal.contains(event.target)
        break;

      case 'keyup':
        shouldDismiss = event.code === 'Escape'
        break;

      default:
        console.warn(`${event.type} haven't been implemented.`)
    }

    if (shouldDismiss) {
      this.dismiss()
    }
  }

  _createOverlay() {
    if (!this.overlay) {
      document.body.insertAdjacentHTML('beforeend', '<div class="modal-overlay"></div>')
      this.overlay = document.querySelector('.modal-overlay')
    }
  }

  _isPortrait() {
    return window.matchMedia('(orientation: landscape) and (max-width: 1023px)').matches
  }

  _stickAnchor(anchor) {
    let parent = anchor.closest('.details')
    if (parent) {
      parent.classList.add('visible')
    }
  }

  _focus() {
    const modal = this.modal
    // we will check if we have an input text that we can focus, if not, we will focus the modal
    // itself
    const input = modal.querySelector('input[type="text"], input[type="email"]')
    if (input) input.focus({ preventScroll: true })
  }

  _handleForm() {
    let modal = this.modal
    let form = modal.querySelector('form')
    // basically the modal manager will be a little bit attached and will check if we have
    // a form in the modal, we will mark it as remote with data type of JSON and will bind an
    // `ajax:success` and `ajax:error` to the form to make it work remotely
    if (form) {
      form.setAttribute('data-remote', true)
      form.setAttribute('data-type', 'json')
      form.addEventListener('ajax:success', (response) => {
        this.dismiss()

        const [data] = response.detail
        if (data.location) {
          window.location = data.location
        } else if (data.reload === undefined || data.reload) {
          window.location.reload()
        } else if (data.notice) {
          FlashManager.showFlash(data.notice, 'notice')
        }
      })

      form.addEventListener('ajax:error', (response) => {
        const [data] = response.detail
        if (data.errors) {
          this._showFieldErrors(form, data.errors)
        } else {
          const error = form.querySelector('.global-error')
          if (error) error.remove()
          form.insertAdjacentHTML('afterbegin', `<span class="form-error global-error">${data.error}</span>`)
          // old implementation, it doesn't make sense to use it because the notification will be put below
          // the overlay which can be confusing for the user
          // FlashManager.showFlash(data.error, 'alert')
        }
      })
    }
  }

  _showFieldErrors(form, errors) {
    // first we clean any old error available
    const oldErrors = form.querySelectorAll('.form-error')
    oldErrors.forEach((error) => error.remove())

    for (let field in errors) {
      const target = form.querySelector(`input[name*=${field}]:not([type="hidden"])`)
      const list = errors[field].join('<br />')
      // at the moment we are only dealing differently with checkboxes, however we need to check
      // if we will have other exceptions, so that we can handle them accordingly
      if (target.type === 'checkbox') {
        target.closest('.inline-checkbox').insertAdjacentHTML('afterend', `<span class="form-error">${list}</span>`)
      } else {
        target.insertAdjacentHTML('afterend', `<span class="form-error">${list}</span>`)
      }
    }
  }

  _dismissModal() {
    if (this.modal) {
      this.modal.remove()
      this.modal = null
    }
  }

  _dismissTrigger() {
    if (this.trigger) {
      let parent = this.trigger.closest('.details')
      if (parent) {
        parent.classList.remove('visible')
      }
      this.trigger = null
    }
  }

  _dismissOverlay() {
    if (this.overlay) {
      this.overlay.remove()
      this.overlay = null
    }
  }

  _validateAnchor(anchor) {
    if (anchor && !(anchor instanceof HTMLElement)) {
      console.warn('Anchor is not a DOM element, trying to get it using querySelector')
      anchor = document.querySelector(anchor)
    }

    return anchor
  }

  _repositionModal() {
    if (this.modal) this._placeModal()
  }

  _showAlert(message) {
    FlashManager.showFlash(message, 'alert')
  }
}

const modalManager = new ModalManager()
export default modalManager
