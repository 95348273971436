import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "droppable", "candidate" ]

  connect() {
    this._addListeners()
  }

  onTrigger(event) {
    const element = event.target
    element.closest('.candidate').setAttribute('draggable', true)
  }

  onTriggerUp(event) {
    const element = event.target
    element.closest('.candidate').removeAttribute('draggable')
  }

  onDrop(event) {
    event.preventDefault()
    const target = event.currentTarget
    const folderID = target.dataset.folder ? target.dataset.folder : ''

    Rails.ajax({
      type: 'PUT',
      url: event.dataTransfer.getData('text/plain'),
      dataType: 'json',
      data: `job_application[job_folder_id]=${folderID}`,
      success: (response) => {
        if (response.reload) window.location.reload()
      }
    })
  }

  onDragOver(event) {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
    this._toogleFolder(event.currentTarget, true)
  }

  onDragLeave(event) {
    this._toogleFolder(event.currentTarget, false)
  }

  onDragStart(event) {
    event.dataTransfer.setData('text/plain', event.target.dataset.url)
    this.currentFolder.classList.add('disabled')
  }

  _addListeners() {
    this.candidateTargets.forEach((candidate) => {
      candidate.dataset.action = `dragstart->${this.scope.identifier}#onDragStart dragend->${this.scope.identifier}.onDrag`

      let sortable = candidate.querySelector('.sortable-handle')
      if (sortable) sortable.dataset.action = `mouseup->${this.scope.identifier}#onTriggerUp mousedown->${this.scope.identifier}#onTrigger`
    })

    this.droppableTargets.forEach((droppable) => {
      droppable.dataset.action = this._eventsForDroppable().join(' ')
    })

    document.addEventListener('dragend', (event) => {
      event.target.removeAttribute('draggable')
      this.currentFolder.classList.remove('disabled')
    })
  }

  _eventsForDroppable() {
    const events = [ 'Drop', 'DragOver', 'DragLeave' ]
    return events.map((event) => `${event.toLowerCase()}->${this.scope.identifier}#on${event}`)
  }

  _toogleFolder(element, open) {
    const icon = element.querySelector('i')
    if (icon) icon.innerText = open ? 'folder_open' : 'folder'

    if (open) {
      element.classList.add('hovering')
    } else {
      element.classList.remove('hovering')
    }
  }

  get currentFolder() {
    return this.droppableTargets.find((droppable) => droppable.dataset.scope === this.data.get('scope'))
  }
}
