import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tablist" ]
  connect() {
    this.keys = { down: 'ArrowDown', end: 'End', home: 'Home', left: 'ArrowLeft', right: 'ArrowRight', up: 'ArrowUp' }
    this.direction = { 'ArrowLeft': -1, 'ArrowUp': -1, 'ArrowRight': 1, 'ArrowDown': 1 }
    this.delay = this._determineDelay()

    const tablist = this.tablistTarget
    this.tabs = tablist.querySelectorAll('[role="tab"]')
    this.panels = this.element.querySelectorAll('[role="tabpanel"]')
    this._addListeners()
    this._activateTab(this.tabs[0], false)
  }

  onClick(event) {
    event.preventDefault()
    this._activateTab(event.target, false)
  }

  onKeydown(event) {
    switch (event.key) {
      case this.keys.end:
        event.preventDefault()
        this._activateTab(this.tabs[this.tabs.length - 1])
      break;
      case this.keys.home:
        event.preventDefault()
        this._activateTab(this.tabs[0])
      break;
      case this.keys.up:
      case this.keys.down:
        this._determineOrientation(event)
      break;
    }
  }

  onKeyup(event) {
    const key = event.key
    if (key === this.keys.left || key === this.keys.right) this._determineOrientation(event)
  }

  onFocus(event) {
    setTimeout(this._checkTabFocus.bind(this), this.delay, event.target)
  }

 _addListeners() {
    const tabs = this.tabs
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i]
      tab.dataset.action = this._eventsForTab()
      tab.index = i
    }
  }

  _eventsForTab() {
    const events = ['click', 'keyup', 'keydown', 'focus']
    return events.map((event) => {
      return `${event}->${this.scope.identifier}#on${event.charAt(0).toUpperCase() + event.slice(1)}`
    }).join(' ')
  }

  _activateTab(tab, setFocus = true) {
    this._deactivateTabs()

    // remove tabindex attribute
    tab.removeAttribute('tabindex')

    // set tab as selected
    tab.setAttribute('aria-selected', 'true')

    // get ID of panel from aria-controls
    let controls = tab.getAttribute('aria-controls')

    // make the panel visible by removing the hidden attribute
    document.getElementById(controls).removeAttribute('hidden')

    if (setFocus) tab.focus()
  }

  // deactivate all tabs and tab panels
  _deactivateTabs() {
    this.tabs.forEach((tab) => {
      tab.setAttribute('tabindex', -1)
      tab.setAttribute('aria-selected', 'false')
    })

    this.panels.forEach((panel) => panel.setAttribute('hidden', 'hidden'))
  }

  _determineOrientation(event) {
    const key = event.key
    const vertical = this.tablistTarget.getAttribute('aria-orientation') == 'vertical'
    let proceed = false

    if (vertical) {
      if (key === this.keys.up || key === this.keys.down) {
        event.preventDefault()
        proceed = true
      }
    } else {
      if (key === this.keys.left || key === this.keys.right) proceed = true
    }

    if (proceed) this._switchTabOnArrowPress(event)
  }

  _switchTabOnArrowPress(event) {
    const pressed = event.key

    if (this.direction[pressed]) {
      const target = event.target
      if (target.index !== undefined) {
        if (this.tabs[target.index + this.direction[pressed]]) {
          this.tabs[target.index + this.direction[pressed]].focus()
        } else if (pressed === this.keys.left || pressed === this.keys.up) {
          this._focusLastTab()
        } else if (pressed === this.keys.right || pressed === this.keys.down) {
          this._focusFirstTab()
        }
      }
    }
  }

  _focusFirstTab() {
    this.tabs[0].focus()
  }

  _focusLastTab() {
    this.tabs[this.tabs.length - 1].focus()
  }

  _determineDelay() {
    const hasDelay = this.tablistTarget.hasAttribute('data-delay')
    let delay = 0

    if (hasDelay) {
      let delayValue = this.tablistTarget.getAttribute('data-delay')
      delay = delayValue ? delayValue : 300
    }

    return delay
  }

  _checkTabFocus(target) {
    let focused = document.activeElement
    if (target === focused) this._activateTab(target, false)
  }
}
