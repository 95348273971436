import { Controller } from "stimulus"
import Rails from "rails-ujs"
import FlashManager from "../../managers/flash_manager"

export default class extends Controller {
  static targets = [ "element", "field", "dropzone", "preview", "remove", "button" ]

  connect() {
    this.elementTarget.classList.remove('hidden')
    this.fieldTarget.classList.add('hidden')
    this.fieldTarget.dataset.action = 'change->forms--uploader#showPreview'
    this.fieldTarget.accept = 'image/png, image/jpeg'
    this.dropzoneTarget.dataset.action = 'click->forms--uploader#openFileBrowser'
    this.buttonTarget.setAttribute('for', this.fieldTarget.id)
    this.removeTarget.setAttribute('disabled', true)
    this._addListeners()
    this._setPreviewImage(this.data.get('file'))
  }

  showPreview(event) {
    // this is the hacky way, if we drop multiple files we will have a problem that only
    // the last ones is attached, so we will grab the last one instead of the first
    const size = event.target.files.length - 1
    const file = event.target.files[size]

    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      FlashManager.showFlash('We only accept JPG or PNG images.', 'alert')
      return
    }

    if (file.size > this.sizeLimit) {
      FlashManager.showFlash('This image exceeds the allowed limit.', 'alert')
      return
    }

    const blobURL = URL.createObjectURL(file)
    this._setPreviewImage(blobURL)
    this.removeTarget.removeAttribute('disabled')
  }

  openFileBrowser() {
    this.buttonTarget.click()
  }

  doRemove(event) {
    const target = event.target
    event.preventDefault()
    Rails.ajax({
      type: 'DELETE',
      dataType: 'json',
      url: target.href,
      success: (response) => {
        if (response.success) {
          target.parentNode.remove()
          this.data.set('file', '')
          this.removeFile(event)
        }
      }
    })
  }

  removeFile(event) {
    event.preventDefault()
    this.fieldTarget.value = ''
    this._resetPreviewImage()
    this.removeTarget.setAttribute('disabled', true)
    this._setPreviewImage(this.data.get('file'))
  }

  _addListeners() {
    this.dropzoneTarget.addEventListener('dragover', this._onDragOver.bind(this))
    this.dropzoneTarget.addEventListener('drop', this._onDrop.bind(this))
  }

  _onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  _onDrop(event) {
    event.preventDefault()

    // REVIEW: we are doing it this way, but this is kind of exotic and doesn't work well
    // on older browsers, so we need to find a way of make this work ... or ... just an
    // enhancement for modern browsers
    this.fieldTarget.files = event.dataTransfer.files
    this.fieldTarget.dispatchEvent(new Event('change'))
  }

  _setPreviewImage(url) {
    if (url === '' || url === undefined) {
      return
    }

    let previewer = this.previewTarget
    previewer.classList.add('is-previewing')
    previewer.style.backgroundImage = `url(${url})`
  }

  _resetPreviewImage() {
    let previewer = this.previewTarget
    previewer.classList.remove('is-previewing')
    previewer.style.backgroundImage = ''
  }

  get sizeLimit() {
    return parseInt(this.data.get('limit') || 20971520)
  }
}
