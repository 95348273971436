import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "active", "date" ]

  connect() {
    if (this.activeTarget.checked) {
      this.dateTarget.closest('.field').classList.add('disabled')
      this.dateTarget.setAttribute('tabindex', -1)
    }
  }

  onChange() {
    // we will retrieve the flatpickr instance, we move away
    // if for some reason we don't have access to the instance
    let flatpickr = this.dateTarget._flatpickr
    if (!flatpickr) {
      return
    }

    let field = flatpickr._input.closest('.field')
    if (this.activeTarget.checked) {
      this.selectedDate = flatpickr.selectedDates[0]
      flatpickr.clear()
      flatpickr.altInput.setAttribute('tabindex', -1)
      field.classList.add('disabled')
    } else {
      flatpickr.setDate(this.selectedDate)
      flatpickr.altInput.setAttribute('tabindex', 0)
      field.classList.remove('disabled')
    }
  }
}
