import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.dataset.action = `${this.scope.identifier}#onChange`
  }

  onChange() {
    window.location.href = this.url.replace('__ID__', this.element.value)
  }

  get url() {
    return this.data.get('url')
  }
}
