import { Controller } from "stimulus"
import Plyr from "plyr"

export default class extends Controller {
  static targets = [ "input", "embed" ]

  connect() {
    if (this.data.get('url')) this._doEmbedForUrl(this.data.get('url'))
  }

  loadVideo(event) {
    if (event.target.value !== '') this._doEmbedForUrl(event.target.value)
  }

  _doEmbed(url) {
    const iframe = `
      <iframe src="${url}" frameBorder="0" allow="accelerometer; autoplay; picture-in-picture" allowFullscreen="true"></iframe>
    `
    this.embedTarget.innerHTML = iframe
    this.player = new Plyr(this.embedTarget)
  }

  _getUrlComponents(url) {
    const protocols = new RegExp('^(https?:\/\/)')
    if (!protocols.test(url)) url = `https://${url}`
    return new URL(url)
  }

  _isYouTube(url) {
    return /youtu/.test(url.host)
  }

  _isVimeo(url) {
    return /vimeo/.test(url.host)
  }

  _embedForYouTube(url) {
    let videoID = url.searchParams.get('v')
    if (!videoID) videoID = url.pathname.slice(1)
    return `https://www.youtube.com/embed/${videoID}`
  }

  _embedForVimeo(url) {
    return `https://player.vimeo.com/video/${url.pathname.slice(1)}`
  }

  _doEmbedForUrl(url) {
    url = this._getUrlComponents(url)

    if (this._isYouTube(url)) {
      this._doEmbed(this._embedForYouTube(url))
    } else if (this._isVimeo(url)) {
      this._doEmbed(this._embedForVimeo(url))
    }
  }
}
