import Rails from "rails-ujs"
import * as Utils from "./common"

class RemoteSearch {
  static fetch(url, params, callback, method = 'GET') {
    Rails.ajax({
      type: method,
      url: url,
      data: Utils.stringify(params),
      success: (response) => callback(response)
    })
  }
}

export default RemoteSearch
