import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "expander", "links" ]

  connect() {
    this.linksTarget.classList.add('animated')
  }

  toggle() {
    let expander = this.expanderTarget
    let links = this.linksTarget

    // we need to do match media query, because we only want to do this for desktop
    // versions, another implementation should be needed for smaller screens
    let mql = window.matchMedia('(min-width: 64em)')

    if (mql.matches) {
      if (links.classList.contains('is-expanded')) {
        expander.style.right = 'auto'
      } else {
        expander.style.right = 0
      }
    }

    // then we just toggle the class, instead of using `add` or `remove`
    links.classList.toggle('is-expanded')
  }

  trigger(event) {
    let links = this.linksTarget
    let target = event.currentTarget
    target.classList.toggle('is-open')

    if (target.classList.contains('is-open')) {
      links.classList.remove('hidden')
      links.classList.remove('fadeOutLeft')
      links.classList.add('fadeInLeft')
      document.body.classList.add('overflow-y-hidden')
    } else {
      links.classList.add('fadeOutLeft')
      links.classList.remove('fadeInLeft')
      document.body.classList.remove('overflow-y-hidden')
      // for fadeOut we need to have a timeout that should match the time
      // of the animation, and only after that we can add the class hidden
      // we could use anination events listeners, but for now this should do
      setTimeout(function() {
        links.classList.add('hidden')
      }, 1000)
    }
  }
}
