class ClickOutside {
  constructor() {
    this.onClickOutside = this._onClickOutside.bind(this)
    this.scope = null
    this.callback = null

    // we just remove any scope that we have set, and remove the event
    // listener on document click
    window.addEventListener('beforeunload', () => {
      if (this.scope) this.cleanup()
    })
  }

  _onClickOutside(event) {
    if (!this.scope) return
    if (!this.scope.element.contains(event.target)) this.callback.call(this.scope)
  }

  register(element, callback) {
    if (this.scope) this.callback.call(this.scope)
    this.scope = element
    this.callback = callback
    document.addEventListener('click', this.onClickOutside)
  }

  deregister() {
    this.cleanup()
  }

  cleanup() {
    this.scope = null
    this.callback = null
    document.removeEventListener('click', this.onClickOutside)
  }
}

const clickOutside = new ClickOutside()
export default clickOutside
