import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "progress" ]

  connect() {
    this.labelTarget.innerText = this.progress
    this._setProgressRing()
  }

  _setProgressRing() {
    const radius = this.progressTarget.r.baseVal.value
    const circunference = radius * 2 * Math.PI
    const offset = circunference - this.progress / 100 * circunference
    this.progressTarget.style.strokeDasharray = `${circunference} ${circunference}`
    this.progressTarget.style.strokeDashoffset = offset
  }

  get progress() {
    return parseInt(this.data.get('progress'))
  }
}
