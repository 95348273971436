import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "options" ]

  connect() {
    // we need to store the original label, because then we will change between
    // them but want to keep the original label just in case
    if (this.hasLabelTarget) this.label = this.labelTarget.innerText
    this._setSelected()
  }

  setOption() {
    this._setSelected()

    const mql = window.matchMedia('(max-width: 1023px)')
    if (mql.matches) return

    this.element.dispatchEvent(new CustomEvent('search', { bubbles: true }))
  }

  _setSelected() {
    let selected = this.optionsTarget.querySelector('input:checked')
    if (selected && this.hasLabelTarget) {
      this.labelTarget.innerText = selected.nextSibling.innerText
    }
  }
}
