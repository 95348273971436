import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slot" ]

  initialize() {
    this.onRefresh = this._onRefresh.bind(this)
    this.slots = []
    this.intervalID = null
  }

  connect() {
    googletag.cmd.push(() => {
      this.slotTargets.forEach((slot) => {
        const sizes = JSON.parse(slot.dataset.sizes)
        const adSlot = googletag.defineSlot(slot.dataset.unit, sizes.ads, slot.id).addService(googletag.pubads())
        if (sizes.mappings.length > 0) {
          const mapping = googletag.sizeMapping()
          sizes.mappings.forEach((item) => {
            mapping.addSize(item[0], item[1])
          })
          adSlot.defineSizeMapping(mapping.build())
          this.slots.push(adSlot)
        }
      })

      googletag.pubads().setTargeting('Test', ['refresh'])
      googletag.pubads().setTargeting('url', window.parent.location.pathname)
      googletag.pubads().enableSingleRequest()
      googletag.pubads().collapseEmptyDivs()
      googletag.pubads().setForceSafeFrame(false)
      googletag.enableServices()
    })

    this._reloadAds()
    this.element.addEventListener('content:refresh', this.onRefresh)
    this.intervalID = setInterval(() => this._refreshAds(), 50000)
  }

  disconnect() {
    this.element.removeEventListener('content:refresh', this.onRefresh)
    if (this.intervalID) clearInterval(this.intervalID)
  }

  _reloadAds() {
    this.slotTargets.forEach((slot) => {
      if (!slot.dataset.displayed) {
        googletag.cmd.push(() => {
          googletag.display(slot)
        })
        slot.dataset.displayed = 1
      }
    })
  }

  _refreshAds() {
    this.slots.forEach((slot) => {
      googletag.pubads().refresh([slot])
    })
  }

  _onRefresh() {
    this.slotTargets.forEach((slot) => {
      if (!slot.dataset.displayed) {
        const sizes = JSON.parse(slot.dataset.sizes)
        const adSlot = googletag.defineSlot(slot.dataset.unit, sizes.ads, slot.id).addService(googletag.pubads())
        if (sizes.mappings.length > 0) {
          const mapping = googletag.sizeMapping()
          sizes.mappings.forEach((item) => {
            mapping.addSize(item[0], item[1])
          })
          adSlot.defineSizeMapping(mapping.build())
          this.slots.push(adSlot)
        }
      }
    })

    this._reloadAds()
  }
}
