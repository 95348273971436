import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "price", "input" ]

  connect() {
    this._displayPrice()
  }

  onChange() {
    this._displayPrice()
  }

  _displayPrice() {
    const total = this._calculate()
    const currency = this.priceTarget.dataset.currency
    const formatted = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: currency }).format(total)
    this.priceTarget.innerText = formatted
  }

  _calculate() {
    const options = this.inputTargets
      .filter((input) => input.checked)
      .map((input) => parseFloat(input.dataset.price))
    return options.reduce((prev, curr) => prev + curr)
  }
}
