class FlashManager {
  showFlash(message, type = 'notice') {
    const stack = this._getStack()

    const notification = `
      <div class="flash flash-${type} lg:py-4" data-controller="ui--flash">
        <div class="flash-body container lg:pr-0">
          <div class="flex justify-between items-start lg:items-center">
            <p data-target="ui--flash.message">${message}</p>

            <button type="button" class="leading-none ml-4" data-action="ui--flash#onDismiss">
              <i class="material-icons-outlined align-middle lg:hidden">close</i>
              <span class="link hidden lg:block hover:no-underline focus:no-underline">Dismiss</span>
            </button>
          </div>
        </div>
      </div>
    `
    stack.insertAdjacentHTML('beforeend', notification)
  }

  _getStack() {
    let stack = document.querySelector('.flash-stack')
    if (!stack) {
      stack = document.createElement('div')
      stack.classList.add('flash-stack')
      document.querySelector('main').prepend(stack)
    }
    return stack
  }
}

const flashManager = new FlashManager()
export default flashManager
