import { Controller } from "stimulus"
import ClickOutside from "../../utils/click_outside"

export default class extends Controller {
  static targets = [ "content", "static", "button" ]

  connect() {
    this.isCollapsed = false
    this.isOpen = false
    this._addListeners()
  }

  disconnect() {
    ClickOutside.deregister()
  }

  toggle(event) {
    event.stopPropagation()
    const pressed = event.currentTarget.getAttribute('aria-pressed') === 'true'
    pressed ? this._closeMenu() : this._openMenu()
  }

  navigate(event) {
    // we are using currentTarget instead of target, because we have a label and icon
    // there, so sometimes we don't receive the button himself but one of the childs
    let button = event.currentTarget

    // get ID of the sliding option to show
    let controls = button.getAttribute('aria-controls')
    let target = document.getElementById(controls)
    target.classList.add('visible')
    target.style.left = '0%'

    // remove alternate header color
    this.contentTarget.classList.remove('alternate-header')
    this.isCollapsed = true

    // make default menu not available for tabbing, by hiding it
    this.staticTarget.classList.add('invisible')
    this.contentTarget.scrollTop = 0
  }

  dismiss(event) {
    let button = event.target

    // get the sliding option to hidden
    let panel = button.closest('.account-option')
    panel.style.left = '100%'

    // add alternate header color
    this.contentTarget.classList.add('alternate-header')
    this.isCollapsed = false

    // revert status of default menu, we will also change focus to the first item
    // of links
    this.staticTarget.classList.remove('invisible')
    // IDEA: maybe we could store the index of clicked link and use it as reference
    // to focus
    this.staticTarget.querySelector('.account-link').focus()
  }

  // for now we will only handle `Escape` to close the menu, but in the future
  // we need to check what other options should exist
  onKeyboard(event) {
    if (event.code === 'Escape' && this.isOpen) {
      this._closeMenu()
    }
  }

  _openMenu() {
    let content = this.contentTarget
    content.removeAttribute('hidden')
    content.classList.add(this.animations.in)

    // toggle the button state to inform that is pressed
    this.buttonTarget.setAttribute('aria-pressed', 'true')
    this.isOpen = true

    // bind clicks on document to know when to close the menu
    if (this._isDesktop()) {
      ClickOutside.register(this, this._closeMenu)
    } else {
      document.body.classList.add('overflow-y-hidden')
    }
  }

   _closeMenu() {
    this.contentTarget.classList.add(this.animations.out)
    this.buttonTarget.setAttribute('aria-pressed', 'false')
    this.isOpen = false
    if (!this._isDesktop()) document.body.classList.remove('overflow-y-hidden')
    ClickOutside.deregister()
  }

  _addListeners() {
    let self = this

    // handle animation end we need to check if is a `fadeOutUp`, in that case
    // we need to add the attribute hidden
    self.contentTarget.addEventListener('animationend', function(event) {
      let target = event.currentTarget

      // we remove all the animations
      Object.values(self.animations).forEach((property) => {
        target.classList.remove(property)
      })

      if (event.animationName == self.animations.out) {
        target.setAttribute('hidden', 'hidden')
      }
    })

    // handle transition end, we will look for left property transition event
    // and remove visibility if the submenu is not open
    self.contentTarget.addEventListener('transitionend', function(event) {
      if (event.propertyName === 'left' && !self.isCollapsed) {
        event.target.classList.remove('visible')
      }
    })

    // create a match media listener that will change the animations according
    // with the desired breakpoint
    let mql = window.matchMedia('(min-width: 64em)')
    mql.addListener(this._setAnimations.bind(self))
    this._setAnimations(mql)
  }

  _setAnimations(event) {
    if (event.matches) {
      this.animations = { in: 'fadeInDown', out: 'fadeOutUp' }
    } else {
      this.animations = { in: 'fadeInRight', out: 'fadeOutRight' }
    }
  }

  _isDesktop() {
    return window.matchMedia('(min-width: 64rem').matches
  }
}
