import Rails from "rails-ujs"

class BlockManager {
  static classForSection() { return 'section-block' }
  static classForDeletedSection() { return 'section-block--deleted' }

  static add(url, type, position) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        data: `section[section_type]=${type}&position=${position}`,
        success: (data) => resolve(data),
        error: (data) => reject(data)
      })
    })
  }

  static reorder(sections) {
    let startAt = 1
    sections.forEach((element) => {
      if (!element.classList.contains(BlockManager.classForDeletedSection())) {
        let position = element.querySelector("input[name*='position']")
        position.value = startAt
        startAt++
      }
    })
  }
}

export default BlockManager
