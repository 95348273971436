import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submit" ]

  connect() {
    this.checkValidity()
  }

  checkValidity() {
    const valid = this.element.checkValidity()
    if (valid) {
      this.submitTarget.removeAttribute('disabled')
    } else {
      this.submitTarget.setAttribute('disabled', true)
    }
  }
}
