// import just the need brand icons from fontawesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
// import one by one just for readibility
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faVimeoV } from '@fortawesome/free-brands-svg-icons/faVimeoV'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faBehance } from '@fortawesome/free-brands-svg-icons/faBehance'

// add the icons to the library
library.add(faFacebookF, faTwitter, faVimeoV, faYoutube, faInstagram, faLinkedin, faBehance)

// import currency icons
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins'
import { faRubleSign } from '@fortawesome/free-solid-svg-icons/faRubleSign'
import { faYenSign } from '@fortawesome/free-solid-svg-icons/faYenSign'
import { faPoundSign } from '@fortawesome/free-solid-svg-icons/faPoundSign'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign'

// add the icons to the library
library.add(faCoins, faRubleSign, faYenSign, faPoundSign, faEuroSign, faDollarSign)

// add the watcher to replace any icon call by the real representation
dom.watch()
